import { useState, useEffect, useRef } from 'react';

import OtpInput from 'react-otp-input';

import styles from './Check.module.scss';

function Check() {
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
  const [otpValue, setOtpValue] = useState('');
  const formRef = useRef();

  useEffect(() => {
    if (otpValue.length === 6 && formRef?.current) {
      formRef.current.submit();
    }
  }, [otpValue, formRef]);

  return (
    <>
      <OtpInput
        value={otpValue}
        onChange={setOtpValue}
        numInputs={6}
        containerStyle={styles.check}
        inputStyle={styles.input}
        shouldAutoFocus
        isInputNum
      />
      <form method="POST" ref={formRef}>
        <input type="hidden" value={csrfToken} name="authenticity_token" />
        <input type="hidden" value={otpValue} name="user[otp_attempt]" />
      </form>
    </>
  );
}

export default Check;
